import React, { Component } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Navbar, Nav, Image, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Foot from "./components/foot/foot.component";

import About from "./screens/about/about.screen";
import Cars from "./screens/cars/cars.screen";
import Contact from "./screens/contact/contact.screen";
import Lorry from "./screens/lorry/lorry.screen";
import Ships from "./screens/ships/ships.screen";
import Tracking from "./screens/tracking/tracking-screen";
import TrackingDetails from "./screens/tracking-details/tracking-details.screen";
import Home from "./screens/home/home.screen";
import TermsOfService from './screens/terms-of-service/terms-of-service.screen';

import i18n from './i18n';
import i18next from 'i18next';
import { GlobalState } from "./components/global-state/global-state.component";
import Assurance from "./screens/insurance/insurance.screen";
import Djassa from "./screens/djassa/djassa.screen";
import ServerProvider from "./components/server/server.component";
import Privacy from "./screens/privacy/privacy.screen";
import Imprint from "./screens/imprint/imprint.screen";

import Notifications from 'react-notify-toast';

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    return true;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <GlobalState.Provider value={this.state}>
        <ServerProvider>
          <Router>
            <Navbar bg="custom" expand="lg" sticky="top">
              <LinkContainer to="/">
                <Navbar.Brand>
                  <Image src="/assets/Logo-name.png" style={{ height: 50, margin: -13 }} />
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer to="/">
                    <Nav.Link>{t("Home")}</Nav.Link>
                  </LinkContainer>
                  <NavDropdown title={t("OurServices")} id="basic-nav-dropdown">
                    <LinkContainer to="/service/lorry">
                      <NavDropdown.Item>{t("Lorry")}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/service/car">
                      <NavDropdown.Item>{t("Automobile")}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/service/ship">
                      <NavDropdown.Item>{t("Ship")}</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/service/assurance">
                      <NavDropdown.Item>{t("Insurance")}</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <LinkContainer to="/about">
                    <Nav.Link>{t("About")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/tracking">
                    <Nav.Link>{t("Tracking")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact">
                    <Nav.Link>{t("Contact")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/djassa">
                    <Nav.Link>{t("Djassa")}</Nav.Link>
                  </LinkContainer>
                  <NavDropdown title={i18next.language} id="language-dropdown">
                    <Nav.Link href="#" onClick={() => this.changeLanguage('de')}>de</Nav.Link>
                    <Nav.Link href="#" onClick={() => this.changeLanguage('fr')}>fr</Nav.Link>
                    <Nav.Link href="#" onClick={() => this.changeLanguage('en')}>en</Nav.Link>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Notifications options={{zIndex: 10000, top: '50px'}}/>

            <div className="main">
              <Switch>
                <Route path="/about" component={About} />
                <Route exact path="/tracking">
                  <Tracking />
                </Route>
                <Route path="/tracking/:customerId/:trackingId">
                  <TrackingDetails />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/service/car">
                  <Cars />
                </Route>
                <Route path="/service/lorry">
                  <Lorry />
                </Route>
                <Route path="/service/ship">
                  <Ships />
                </Route>
                <Route path="/service/assurance">
                  <Assurance />
                </Route>
                <Route path="/djassa">
                  <Djassa />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/termsofservice">
                  <TermsOfService />
                </Route>
                <Route path="/privacy">
                  <Privacy />
                </Route>
                <Route path="/imprint">
                  <Imprint />
                </Route>
              </Switch>
            </div>
            <Foot />
          </Router>
        </ServerProvider>
      </GlobalState.Provider>
    );
  }
}

export default withTranslation()(App);
