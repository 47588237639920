import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TextPage from "../../components/text-page/text-page.component";

class Ships extends Component {
  render() {
    const { t } = this.props;
    return (
      <TextPage>
        <h3>{t("ShipTitle")}</h3>
        <p>{t("ShipText1")}</p>
        <p>{t("ShipText2")}</p>
        <p>{t("ShipText3")}</p>
        <ul>
          <li>{t("ShipText4a")}</li>
          <li>{t("ShipText4b")}</li>
          <li>{t("ShipText4c")}</li>
          <li>{t("ShipText4d")}</li>
          <li>{t("ShipText4e")}</li>
        </ul>
        <p>{t("ShipText5")}</p>
        <p>{t("ShipText6")}</p>
      </TextPage>
    );
  }
}

export default withTranslation()(Ships);
