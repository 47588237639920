import { Alert } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './text-area.module.css';

const TextArea = ({ label, placeholder, value, name, onKeyPress, register, watch, errors, required }) => {
    const { t } = useTranslation("validation");
    const l = watch(name) ? <div className={styles.label}>{label} {(required ? "*" : "")}</div> : <div className={styles.label}>{""}</div>;
    return (
        <div className={styles.textarea}>
            {l}
            <textarea
                rows={5}
                name={name}
                ref={register({ required })}
                defaultValue={value ? value : ""}
                onKeyPress={onKeyPress ? onKeyPress : () => {}}
                placeholder={(placeholder ? placeholder : label) + (required ? " *" : "")} />
            {errors[name] && <Alert variant="danger">{t(errors[name].type)}</Alert>}
        </div>
    )
}

export default TextArea
