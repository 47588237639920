import { useEffect, useRef, useState } from "react"

const useLocalState = (key, defaultValue = '', { serialize = JSON.stringify, deserialize = JSON.parse } = {}) => {
    const [state, setState] = useState(() => {
        const valueInLocalStorage = localStorage.getItem(key)
        if (valueInLocalStorage) {
            try {
                console.log("value in local storage", key, valueInLocalStorage, typeof valueInLocalStorage)
                return deserialize(valueInLocalStorage)
            } catch (error) {
                localStorage.removeItem(key)
            }
        }
        console.log("default value for localState", key, defaultValue)
        return typeof defaultValue === 'function' ? defaultValue() : defaultValue
    })

    const prevKeyRef = useRef(key)

    useEffect(() => {
        const prevKey = prevKeyRef.current
        if (prevKey !== key) {
            localStorage.removeItem(prevKey)
        }
        prevKeyRef.current = key
        const newValue = serialize(state)
        console.log("set localState to", key, newValue);
        localStorage.setItem(key, newValue)
    }, [key, state, serialize])

    return [state, setState]
}

export default useLocalState