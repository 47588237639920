import React, { Component } from "react";
import styles from "./foot.module.css";
import { Container, Image, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";

class Foot extends Component {
  render() {
    const { t } = this.props;
    return (
      <Container fluid className={styles.foot}>
        <Row>
          <Col>
            <Image src="/assets/logo.png" height="115" className={styles.logo} /><br />
            {t("FootInfo")}
          </Col>
          <Col>
            <h3>{t("Contact")}</h3>
            <div>D.S'FTS International</div>
            <div>Langestraße 136</div>
            <div>27580 Bremerhaven</div>
            <div>{t("Phone")}: (+49) 1521 18 66 691</div>
            <div>Trecheville AV21 (non loin de la gare de Bassam)</div>
            <div>{t("Phone")}: (+225) 09 71 20 62 / 06 15 97 83</div>
            <div>{t("Email")}: info@dsfts.cf</div>
          </Col>
          <Col>
            <h3>{t("OurServices")}</h3>
            <LinkContainer to="/service/lorry">
              <div>&gt; {t("Lorry")}</div>
            </LinkContainer>
            <LinkContainer to="/service/car">
              <div>&gt; {t("Automobile")}</div>
            </LinkContainer>
            <LinkContainer to="/service/ship">
              <div>&gt; {t("Ship")}</div>
            </LinkContainer>
            <LinkContainer to="/service/assurance">
              <div>&gt; {t("Insurance")}</div>
            </LinkContainer>
            <LinkContainer to="/imprint">
              <div>{t("Imprint")}</div>
            </LinkContainer>
            <LinkContainer to="/privacy">
              <div>{t("PrivacyPolicy")}</div>
            </LinkContainer>
          </Col>
          <Col>
            <div>{t("ScanMe")}</div>
            <Image className={styles.qr} src="/QR.png" />
          </Col>
        </Row>
        <div className={styles.copy}>© 2020 - 2021 D.S'FTS International - {t("AllRights")} - <LinkContainer to="/termsofservice"><a href="http://www.dsfts.cf">{t("TermsOfService")}</a></LinkContainer></div>
      </Container>
    );
  }
}
export default withTranslation()(Foot);
