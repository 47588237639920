import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18next from 'i18next';
import TextPage from "../../components/text-page/text-page.component";
import { useForm } from "react-hook-form";
import Input from "../../components/input/input.component";
import useLocalState from "../../components/local-state/local-state.component";

import styles from './tracking.module.css'

const numberPattern = /[0-9]+/;
const trackingPattern = /^[-0-9a-fA-F]+$/;

export default function Tracking() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [customerId, setCustomerId] = useLocalState("customerId", null);
  const [trackingIds, setTrackingIds] = useLocalState("trackingIds", []);
  const [checked, setChecked] = useState(!!customerId)

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("Tracking") + " - D.S.FTS";
  }, [t])

  console.log("trackingIds", trackingIds)

  const searchAndShow = data => {
    console.log("##### save", data.save);
    if (data.save) {
      if (data.customerId === customerId) {
        setTrackingIds([...new Set([...trackingIds, data.trackingId])]);
      } else {
        setCustomerId(data.customerId);
        setTrackingIds([data.trackingId]);
      }
    } else {
      setCustomerId(null);
      setTrackingIds([]);
    }
    history.push("/tracking/" + data.customerId + "/" + data.trackingId);
  };

  var badge = <a href='https://play.google.com/store/apps/details?id=com.dsftsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
    <img alt='Jetzt bei Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' width={300} />
  </a>;
  if (i18next.language === 'fr') {
    badge = <a href='https://play.google.com/store/apps/details?id=com.dsftsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
      <img alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png' width={300} />
    </a>;
  } else if (i18next.language === 'en') {
    badge = <a href='https://play.google.com/store/apps/details?id=com.dsftsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
      <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width={300} />
    </a>;
  }

  return (
    <TextPage>
      <h2>{t("Tracking")}</h2>
      <p>{t("TrackingText")}</p>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        {badge}
      </div>
      {trackingIds.length > 0 && <h4>Histoire</h4>}
      {trackingIds.map(t => <div key={t} className={styles.history} onClick={() => history.push("/tracking/" + customerId + "/" + t)}>{t}</div>)}
      <form onSubmit={handleSubmit(searchAndShow)}>
        <Input value={customerId} label={t("AccountID")} name="customerId" register={register} watch={watch} errors={errors} required type="text" minLength={4} pattern={numberPattern} />
        <Input label={t("TrackingId")} name="trackingId" register={register} watch={watch} errors={errors} required type="text" minLength={5} pattern={trackingPattern} />
        <div>
        <input checked={checked} onChange={({target}) => setChecked(target.checked)} id="save" name="save" type="checkbox" ref={register()} />
        <label for="save">{t('SaveData')}</label>
        </div>
        <Button variant={"primary"} type="submit">
          {t("Show")}
        </Button>
      </form>
    </TextPage>
  );
}
