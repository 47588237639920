import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({error}) => {
    const {t} = useTranslation("errors");
    if (!error) {
        return null;
    }
    if (error.code) {
        return <Alert variant="danger">{t(`Error-${error.code}`)}</Alert>;
    }
    return <Alert variant="danger">{error.message}</Alert>;
}

export default ErrorMessage;