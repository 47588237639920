import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import i18next from 'i18next';
import TextPage from "../../components/text-page/text-page.component";

const de = `Waren, die im Rahmen des Handels transportiert werden, unterliegen neben den Risiken, die mit dem Transport, der Handhabung, dem Laden, dem Entladen und der Lagerung verbunden sind, vielen spezifischen Risiken (Verlust, Diebstahl, Bruch, Nässe usw.). Die Güterverkehrsversicherung deckt diejenigen ab, die auf See, Land, in der Luft und auf dem Fluss befördert werden. Diese Waren können von verschiedener Art sein.

### VERSICHERUNGSBEDINGUNGEN FÜR DEN FRACHTTRANSPORT
    
* Die Art der versicherten Ware.
* Wie diese Waren verpackt werden.
* Die Route und das geografische Gebiet bedient
* Anzahl und Gewicht der zu versendenden Pakete oder Mengen

#### Die Versandmethode

Das hat seine ganze Bedeutung. Für die transportierten Waren besteht eine separate Versicherung. LUFT, STRASSE, SEEWEG.

#### Transportversicherung oder nicht?

Ist es eine gute Idee, eine Transportversicherung abzuschließen? Sind Sie richtig abgesichert, wenn Sie dies nicht tun? Nachfolgend finden Sie alle Antworten.

#### Versicherung

Preis 25% des Wertes Ihrer Waren wie auf der Rechnung angegeben
Wenn Sie Ihre Waren bei D.S'FRET International versichert haben:

Sie sind abgesichert, falls etwas passieren sollte. Wenn Sie eine Transportversicherung haben und ein Vorfall während des Transports auftritt oder die Ware vollständig verloren geht, sind Sie vollständig versichert und werden vollständig entschädigt. Die Transportversicherung ist eine hervorragende Möglichkeit, Risiken während des Transports auszuschließen. Versand von Waren.

#### Wenn Sie keine Transportversicherung haben.

Wenn Ihre Waren während des Transports nicht versichert sind, sind Sie nicht vollständig versichert, wenn Ihre Waren während des Versands beschädigt werden, und Sie haben keine separate Transportversicherung für Schäden, die an Ihren Waren während des Transports auftreten können, sind auf dein Risiko

Um vollständig abgesichert zu sein, müssen Sie daher eine separate Transportversicherung außerhalb des Transports selbst abschließen. Dies sollten Sie in Ihren Bedingungen und in Ihrem Servicevertrag überprüfen, um absolut sicher und abgedeckt zu sein.

#### Internationale Güterverkehrsversicherung

Die Dauer der Garantie wird zum Zeitpunkt des Abschlusses des Versicherungsvertrags festgelegt (von Geschäft zu Ort ... von Geschäft zu Ort ...; von Rand zu Rand einschließlich Be- und Entladevorgängen usw.).

Die Dauer der Garantien ist grundsätzlich begrenzt:

* Luft: 01 Tage nach Erhalt der Ware
* Maritime: 01 Tag nach Erhalt der Ware
* Straße: 02 Tage nach Erhalt der Ware`;

const fr = `Les marchandises acheminées dans le cadre des échanges commerciaux sont soumises à de nombreux risques spécifiques (pertes, vols, casses, mouille …) s’y ajoutent les risques liés aux opérations annexes au transport – manutentions – chargements – déchargements – stockage. L’assurance transport des marchandises concerne celles transportées par voie maritime, terrestre, aérienne et fluviale. Ces marchandises peuvent être de natures différentes.

### CONDITIONS DE L’ASSURANCE TRANSPORT DE FRET
    
* La nature des marchandises assurées.
* Le mode d’emballage de ces marchandises.
* L’itinéraire et la zone géographique desservie
* Le nombre et poids des colis ou quantités à expédier

#### Le mode d’envoi

Celui-ci a toute son importance. Il existe distinctement une police d’assurance pour les marchandises transportées. AÉRIEN, ROUTIER, MARITIME.

#### Assurance transport ou non ?

Est-ce une bonne idée de souscrire une assurance transport? Êtes-vous correctement couvert, si vous n’en avez pas? Vous trouverez toutes les réponses ci-dessous.

#### Assurance

Prix 25% de la valeur de vos Marchandises au vus de la factures 
Lorsque vous assuré  vos Marchandises à D.S'FRET international :

Vous êtes couvert au cas où quelque chose devait arriver. Lorsque vous avez une assurance de transport et qu’un incident survient lors du transport ou que la marchandise est complètement perdue, vous êtes entièrement couvert et vous serez complètement indemnisé Une assurance de transport est un excellent moyen d’éliminer les risques lors de l’expédition des marchandises. 

#### Si vous n’avez pas d’assurance transport.

Lorsque vos Marchandise ne sont pas assurées lors  du transport, vous n’êtes pas entièrement couvert si votre marchandise  est  endommagée pendant l’expédition et que vous  n’avez pas d’assurance transport distincte les dommages  qui pourraient survenir à vos marchandises pendant le transport sont à vos risques  

Par conséquent, afin d’être entièrement couvert, vous devez souscrire une assurance transport séparée en dehors du transport lui-même). C’est quelque chose que vous devez vérifier dans votre accord de modalités et de services afin d’en être absolument sûr et couvert. 

#### Assurance du transport international de marchandises

La durée de la garantie est déterminée au moment de la conclusion du contrat d’assurance (de magasin lieu… à magasin lieu… ; de bord à bord y compris les opérations de chargement et déchargement, etc.). 

La durée des garanties est en règle générale limitée :

* Aérien : 01 jours après la réception de marchandise
* Maritime : 01 jour après la réception de marchandise
* Routier : 02 jours après la réception de marchandise`;

const en = `Goods transported as part of trade are subject to many specific risks (loss, theft, breakage, wetness, etc.) in addition to the risks associated with operations ancillary to transport - handling - loading - unloading - storage. Goods transport insurance covers those transported by sea, land, air and river. These goods can be of different kinds.

### FREIGHT TRANSPORT INSURANCE CONDITIONS
    
* The nature of the goods insured.
* The method of packaging of these goods.
* The route and the geographical area served
* The number and weight of packages or quantities to be shipped

#### The sending method

This has all its importance. There is a separate insurance policy for the transported goods. AIR, ROAD, MARITIME.

#### Transport insurance or not?

Is it a good idea to take out transport insurance? Are you properly covered, if you don't? You will find all the answers below.

#### Insurance

Price 25% of the value of your Goods as seen on the invoice
When you insured your Goods to D.S'FRET international:

You are covered in case something should happen. When you have transport insurance and an incident occurs during transport or the merchandise is completely lost, you are fully covered and you will be fully compensated. Transport insurance is a great way to eliminate risks during transport. shipment of goods.

#### If you don't have transport insurance.

When your Goods are not insured during transport, you are not fully covered if your goods are damaged during shipment and you do not have separate transport insurance for damage that may occur to your goods during transport are at your risk

Therefore, in order to be fully covered, you must take out separate transport insurance outside of the transport itself). This is something you should check in your terms and service agreement to be absolutely sure and covered.

#### International freight transport insurance

The duration of the guarantee is determined at the time of the conclusion of the insurance contract (from store to location ... to store to location ...; from board to board including loading and unloading operations, etc.).

The duration of the guarantees is generally limited:

* Air: 01 days after receipt of goods
* Maritime: 01 day after receipt of goods
* Road: 02 days after receipt of the goods;`;

class Assurance extends Component {
  render() {
    const { t } = this.props;
    document.title = t("Insurance") + " - D.S.FTS";
    var txt = de;
    if (i18next.language === 'fr') {
      txt = fr;
    }
    else if (i18next.language === 'en') {
      txt = en;
    }
    return (
      <TextPage>
        <h3>{t("Insurance")}</h3>
        <ReactMarkdown children={txt} />
      </TextPage>
    );
  }
}

export default withTranslation()(Assurance);
