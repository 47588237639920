import React from 'react'
import i18next from 'i18next';

const Status = ({ status }) => {
    if (!status) {
        return null;
    }
    const lng = i18next.language;
    var v = null;
    switch (lng) {
        case 'de':
            v = status.de;
            break;
        case 'en':
            v = status.en;
            break;
        case 'fr':
            v = status.fr;
            break;
    }
    if (!v) {
        v = status.statusId;
    }
    return <span>{v}</span>;
}

export default Status;
