import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TextPage from "../../components/text-page/text-page.component";

class About extends Component {
  render() {
    const { t } = this.props;
    document.title = t("About") + " - D.S.FTS";
    return (
      <TextPage>
        <h3>{t("About")}</h3>
        <p>{t("AboutText1")}</p>
        <p>{t("AboutText2")}</p>
        <p>{t("AboutText3")}</p>
        <p>{t("AboutText4")}</p>
        <p>{t("AboutText5")}</p>
        <p>{t("AboutText6")}</p>
        <p>{t("AboutText7")}</p>
      </TextPage>
    );
  }
}

export default withTranslation()(About);
