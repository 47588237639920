import React, { useEffect, useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Server } from "../../components/server/server.component";
import Status from "../../components/status/status.component";

import styles from './tracking-details.module.css';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

export default function TrackingDetails() {
  let { customerId, trackingId } = useParams();
  const { t } = useTranslation(["translation", "errors"]);
  const server = useContext(Server);
  const history = useHistory();
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    server.getTracking(customerId, trackingId, res => {
      setData(res);
    }, setError);
  }, [server, customerId, trackingId]);

  const fmt = val => {
    if (!val) { return "---------"; }
    const d = new Date(val);
    return fmtFR.format(d);
  }

  var d = null;

  if (error) {
    if (error.code) {
      d = <Alert variant="danger">{t("errors:Error-" + error.code)}</Alert>;
    } else {
      d = <Alert variant="danger">{error.message}</Alert>;
    }
  } else if (data) {
    d = <div>
      {data.events ? data.events.map(e => <div>{fmt(e.created)} - <Status status={e.status} /> - {e.message}</div>) : null}
    </div>
  } else {
    d = <div>{t("NoData")}</div>;
  }

  return <Container style={{ padding: 30 }}>
    <h2>Tracking</h2>
    <p>{t("TrackingDetailText")}</p>
    <div className={styles.value}>
      <span>{t("AccountID")}:</span>
      <span>{customerId}</span>
    </div>
    <div className={styles.value}>
      <span>{t("TrackingId")}:</span>
      <span>{trackingId}</span>
    </div>
    <div className={styles.value}>
      <span>{t("Events")}</span> <span></span></div>
    {d}
    <div>
      <Button onClick={() => history.goBack()}>{t("Back")}</Button>
    </div>
  </Container>;
}
