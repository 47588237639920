import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class TermsOfService extends Component {
  render() {
    const { t } = this.props;
    document.title = t("TermsOfService") + ' - D.S.FTS International';

    return <Container style={{ padding: 30 }}>
        <h3>{t("TermsOfService")}</h3>
    </Container>;
  }
}
export default withTranslation()(TermsOfService);