import React from 'react'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ReactMarkdown from "react-markdown";
import { Container } from 'react-bootstrap';

const name = 'D.S\'FTS International';
const de = `# Impressum

Verantwortlich im Sinne des § 5 TMG:

Souleymane Diomande

${name}  
Langestrasse 136  
27580 Bremerhaven  
Deutschland

Tel.: +49 (0) 1521 18 66 691

E-Mail: info@dsfts.cf  
Web: https://www.dsfts.cf

Verantwortlicher gemäß § 18 Abs. 2 MStV (V.i.S.d.P.): Souleymane Diomande

### Haftungshinweis

Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer
Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
Da wir auf diese Inhalte keinen Einfluss haben, kann ${name} keine Gewähr dafür
übernehmen. Zum Zeitpunkt der Verlinkung wurden die verlinkten Seiten auf mögliche
Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
erkennbar. Eine regelmäßige Kontrolle der verlinkten Seiten ist jedoch ohne Anhaltspunkte einer
Rechtsverletzung nicht zumutbar.

### Rechtlicher Hinweis

Alle auf www.dsfts.cf veröffentlichten Dateien, Bilder, Dokumente usw. unterliegen dem Copyright
von ${name}. Ein Download oder Ausdruck dieser Veröffentlichungen ist
ausschließlich für den persönlichen oder nicht kommerziellen Gebrauch gestattet. Alle darüber
hinausgehenden Verwendungen, insbesondere die kommerzielle Nutzung und Verbreitung, sind
grundsätzlich nicht gestattet und bedürfen einer Genehmigung von ${name}.
`;
const fr = `# Informations légales

Responsable selon § 5 TMG:

Souleymane Diomande

${name}  
Langestrasse 136  
27580 Bremerhaven  
Allemagne

Tél.: +49 (0) 1521 18 66 691

Courriel: info@dsfts.cf  
Site Web: https://www.dsfts.cf

Responsable selon § 18 Abs.2 MStV (V.i.S.d.P.): Souleymane Diomande

### Avis de responsabilité

Malgré un contrôle minutieux du contenu, nous déclinons toute responsabilité quant au contenu des sites Web externes.
La gauche. Les exploitants des pages liées sont seuls responsables de leur contenu.
Comme nous n'avons aucune influence sur ce contenu, ${name} ne peut le garantir
prendre le contrôle. Au moment de la liaison, les pages liées étaient sur possible
Violations légales vérifiées. Il n'y avait aucun contenu illégal au moment de la liaison
reconnaissable. La vérification régulière des pages liées se fait cependant sans aucune indication
Infraction déraisonnable.

### Mention légale

Tous les fichiers, images, documents, etc. publiés sur www.dsfts.cf sont soumis au droit d'auteur
par ${name}. Un téléchargement ou une impression de ces publications est disponible
Autorisé pour un usage personnel ou non commercial uniquement. Tout à ce sujet
utilisations au-delà, en particulier utilisation commerciale et distribution
généralement interdits et nécessitent l'approbation de ${name}.
`;
const en = `# Imprint

Responsible according to § 5 TMG:

Souleymane Diomande

${name}  
Langestrasse 136  
27580 Bremerhaven  
Germany

Tel .: +49 (0) 1521 18 66 691

Email: info@dsfts.cf  
Web: https://www.dsfts.cf

Responsible according to § 18 Abs. 2 MStV (V.i.S.d.P.): Souleymane Diomande

### Notice of liability

Despite careful content control, we assume no liability for the content of external websites
Left. The operators of the linked pages are solely responsible for their content.
Since we have no influence on this content, ${name} cannot guarantee it
take over. At the time of linking, the linked pages were on possible
Legal violations checked. There was no illegal content at the time of linking
recognizable. Regular checking of the linked pages is, however, without any indication
Infringement unreasonable.

### Legal notice

All files, images, documents, etc. published on www.dsfts.cf are subject to copyright
by ${name}. A download or printout of these publications is available
Permitted for personal or non-commercial use only. All about it
uses beyond that, in particular commercial use and distribution
generally not permitted and require the approval of ${name}.
`;

const Privacy = () => {
    const { t } = useTranslation();
    document.title = t("Imprint") + " - D.S'FTS";
    var txt = de;
    if (i18next.language === 'fr') {
        txt = fr;
    }
    else if (i18next.language === 'en') {
        txt = en;
    }
    return (
        <Container style={{ padding: 30 }}>
            <ReactMarkdown children={txt}>
            </ReactMarkdown>
        </Container>
    );

}

export default Privacy;
