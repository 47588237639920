import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import Input from '../input/input.component';
import TextArea from '../text-area/text-area.component';

const MessageForm = ({ onSendMessage }) => {
    const { t } = useTranslation(["translation", "validation"]);
    const { register, handleSubmit, watch, errors } = useForm();

    const doSend = data => {
        onSendMessage(data);
    };

    return (
        <>
            <p>{t("SendUsMessage")}</p>
            <form onSubmit={handleSubmit(doSend)}>
                <Input label={t("Name")} name="lastname" register={register} watch={watch} errors={errors} required type="text"/>
                <Input label={t("Email")} name="email" register={register} watch={watch} errors={errors} required type="email"/>
                <Input label={t("Subject")} name="subject" register={register} watch={watch} errors={errors} required type="text"/>
                <TextArea label={t("Message")} name="message" register={register} watch={watch} errors={errors} required/>
                <Button variant="primary" type="submit" >
                    {t("Send")}
                </Button>
            </form>
            <div>* {t("validation:required")}</div>
        </>
    )
};

export default MessageForm;
