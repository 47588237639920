import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Input from '../input/input.component';
import TextArea from '../text-area/text-area.component';
import { Alert } from 'react-bootstrap';

import styles from './inquiry-form.module.css';

const InquiryForm = ({ onSendMessage }) => {
    const { t } = useTranslation(["translation", "validation"]);
    const { register, handleSubmit, watch, errors } = useForm();
    const [withId, setWithId] = useState("F");

    const doSend = data => {
        onSendMessage(data);
    };

    const fieldSalutation = withId === "F" ? (
        <div className={styles.radio}>
            <input type="radio" id="m" name="salutation" value="M" ref={register({required: true})} />
            <label for="m">{t("SalutationM")}</label>
            <input type="radio" id="f" name="salutation" value="F" ref={register({required: true})} />
            <label for="f">{t("SalutationF")}</label>
            <input type="radio" id="x" name="salutation" value="X" ref={register({required: true})}/>
            <label for="x">{t("SalutationX")}</label>
            {errors.salutation && <Alert variant="danger">{t("validation:" + errors.salutation.type)}</Alert>}
        </div>
    ) : <Input label={t("AccountID")} name="customerId" register={register} watch={watch} errors={errors} required type="number" />;
    const fieldWithout = withId === "F" ? (
        <>
            <Input label={t("FirstName")} name="firstname" register={register} watch={watch} errors={errors} type="text" />
            <Input label={t("Company")} name="company" register={register} watch={watch} errors={errors} type="text" />
            <Input label={t("Street")} name="street" register={register} watch={watch} errors={errors} type="text" />
            <Input label={t("Zip")} name="zip" register={register} watch={watch} errors={errors} type="text" />
            <Input label={t("City")} name="city" register={register} watch={watch} errors={errors} required type="text" />
            <Input label={t("Country")} name="country" register={register} watch={watch} errors={errors} type="text" />
            <Input label={t("Email")} name="email" register={register} watch={watch} errors={errors} required type="email" />
            <Input label={t("Phone")} name="phone" register={register} watch={watch} errors={errors} required type="phone" />
        </>
    ) : null;
    return (
        <>
            <p>{t("InquiryText")}</p>
            <form onSubmit={handleSubmit(doSend)}>
                <div className={styles.radio}>
                    <Form.Check inline label={t("AccountIDWithout")} type="radio" id="inline-1" value="F" checked={withId === "F"} onChange={(evt) => setWithId('F')} />
                    <Form.Check inline label={t("AccountIDWith")} type="radio" id="inline-2" value="T" checked={withId === "T"} onChange={(evt) => setWithId('T')} />
                </div>
                {fieldSalutation}
                <Input label={t("LastName")} name="lastname" register={register} watch={watch} errors={errors} required type="text" />
                {withId === "F" ? fieldWithout : null}
                <Input label={t("TypeOfGoods")} name="typeOfGoods" register={register} watch={watch} errors={errors} type="text" />
                <Input label={t("ValueOfGoods")} name="value" register={register} watch={watch} errors={errors} type="text" />
                <TextArea label={t("Message")} name="message" register={register} watch={watch} errors={errors} />
                <Button variant="primary" type="submit">
                    {t("Send")}
                </Button>
            </form>
            <div>* {t("validation:required")}</div>
        </>
    )
}

export default InquiryForm
