import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './input.module.css';

const Input = ({ label, placeholder, value, name, type, onKeyPress, register, watch, errors, required, minLength, pattern }) => {
    const { t } = useTranslation("validation");
    const l = watch(name) ? <div className={styles.label}>{label} {(required ? "*" : "")}</div> : <div className={styles.label}>{""}</div>;
    const regOptions= { required };
    if (minLength) {
        regOptions["minLength"] = minLength;
    }
    if (pattern) {
        regOptions["pattern"] = pattern;
    }

    return (
        <div className={styles.input}>
            {l}
            <input
                name={name}
                ref={register(regOptions)}
                type={type}
                defaultValue={value ? value : ""}
                onKeyPress={onKeyPress ? onKeyPress : () => { }}
                placeholder={(placeholder ? placeholder : label) + (required ? " *" : "")} />
            {errors[name] && <Alert variant="danger">{t(errors[name].type)}</Alert>}
        </div>
    )
}

export default Input
