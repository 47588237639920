import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TextPage from "../../components/text-page/text-page.component";

class Lorry extends Component {
  render() {
    const { t } = this.props;
    return (
      <TextPage>
        <h3>{t("LorryTitle")}</h3>
        <p>{t("LorryText1")}</p>
        <p>{t("LorryText2")}</p>
      </TextPage>
    );
  }
}

export default withTranslation()(Lorry);
