import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import { Server } from "../../components/server/server.component";
import InquiryForm from "../../components/inquiry-form/inquiry-form.component";
import MessageForm from "../../components/message-form/message-form.component";
import { useHistory } from "react-router-dom";
import ErrorMessage from "../../components/error-message/error-message.component";
import TextPage from "../../components/text-page/text-page.component";
import { notify } from 'react-notify-toast';

import styles from './contact.module.css';

const Contact = () => {
  const [selectedTab, setSelectedTab] = useState("message");
  const [error, setError] = useState();
  const history = useHistory();
  const server = useContext(Server);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("Contact") + ' - D.S.FTS International';
  }, [t])

  const sendMessage = body => {
    body.type = selectedTab;
    console.log("send message/inquiry", body);
    server.createInquiry(body, () => {
      notify.show(t('SavedSuccessfully'), 'success', 3000);
      history.push("/");
    }, err => {
      setError(err)
    });
  };

  return (
    <TextPage>
      <div className={styles.doubleColumn}>
        <div>
          <h2>{t("Contact")}</h2>
          <div>D.S'FTS International</div>
          <div>Langestraße 136</div>
          <div>27580 Bremerhaven</div>
          <div>{t("Phone")}: (+49) 1521 18 66 691</div>
          <div>Trecheville AV21 (non loin de la gare de Bassam)</div>
          <div>{t("Phone")}: (+225) 09 71 20 62 / 06 15 97 83</div>
          <div>{t("Email")}: info@dsfts.cf</div>
        </div>
        <div>
          <ErrorMessage error={error} />
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" activeKey={selectedTab} onSelect={k => setSelectedTab(k)}>
            <Tab eventKey="message" title={t("Message")}>
              <MessageForm onSendMessage={sendMessage} />
            </Tab>
            <Tab eventKey="inquiry" title={t("Inquiry")}>
              <InquiryForm onSendMessage={sendMessage} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </TextPage>
  );
}

export default Contact;
