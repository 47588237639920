import React, { createContext } from 'react'

export const Server = createContext();

const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "https://www.dsfts.cf/api";

function executeGet(url, token, callback, errorCallback) {
    console.log("executeGet", "url=" + url, "token=" + token, "callback", callback, "errorCallback", errorCallback);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if (token) {
        myHeaders.append("Authorization", "Bearer " + token);
    }

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(baseURL + url, requestOptions)
        .then(response => {
            console.log("received", response);
            if (response.status === 200) {
                console.log("status 200");
                return response.json();
            }
            if (response.status === 204) {
                console.log("status 204");
                return {};
            }
            if (response.headers.get("Content-Type") === "application/json") {
                console.log("error with json");
                return response.json().then(error => Promise.reject(error));
            }
            console.log("error without json");
            return response.text().then(text => Promise.reject({ message: text }));
        })
        .then(result => callback(result))
        .catch(error => {
            if (errorCallback)
                errorCallback(error);
        });
}

function executeQuery(type, token, query, callback, errorCallback) {
    if (query)
        executeGet("/" + type + "?q=" + query, token, callback, errorCallback);
    else
        executeGet("/" + type, token, callback, errorCallback);
}

function executePost(url, body, token, callback, errorCallback) {
    console.log("executePost " + url, body);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    if (token) {
        myHeaders.append("Authorization", "Bearer " + token);
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    };
    if (body) {
        requestOptions.body = JSON.stringify(body);
    }
    console.log("executePost " + url, requestOptions.body);

    fetch(baseURL + url, requestOptions)
        .then(response => {
            console.log("received", response);
            if (response.status === 200) {
                console.log("status 200");
                response.json().then(result => callback(result));
                return;
            }
            if (response.status === 204) {
                console.log("status 204");
                callback({});
                return;
            }
            if (response.headers.get("Content-Type") === "application/json") {
                try {
                console.log("error with json");
                response.json().then(error => errorCallback(error)).catch(error => errorCallback(error));
                return;
                } catch (e) {
                    console.log("+++++++++++++++++++++++++");
                }
            }
            console.log("error without json");
            response.text().then(text => errorCallback({ message: text })).catch(error => errorCallback(error));
        })
        .catch(error => {
            if (errorCallback)
                errorCallback(error);
        });
}

function createItem(type, body, callback, errorCallback) {
    executePost("/" + type, body, null, callback, errorCallback);
}

const ServerProvider = ({children}) => {
    return (
        <Server.Provider value={{
            baseURL,
            getTracking: (customerId, trackingId, callback, errorCallback) => executeGet(`/customers/${customerId}/tracking/${trackingId}`, null, callback, errorCallback),
            getAds: (callback, errorCallback) => executeQuery("ads", null, null, callback, errorCallback),

            createInquiry: (body, callback, errorCallback) => createItem("inquiries", body, callback, errorCallback),
        }}>
            {children}
        </Server.Provider>
    );
}

export default ServerProvider;
