import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import i18next from 'i18next';
import TextPage from "../../components/text-page/text-page.component";

const de = `# Bundes-Djassa

## Sie wollen Waren verkaufen oder eine Werbung schalten?

Mit dem Bundes-Djassa bieten wird unseren Kunden einen kostengünstigen Weg, Waren zu verkaufen oder Werbung zu schalten.

Kontaktieren Sie unsere Marketing-Abteilung!

* Email: marketing@dsfts.cf
* Telefon: +49 1521 18 66 691
`;
const fr = `# Bundes Djassa

## Voulez-vous vendre des biens ou placer une annonce?

Avec le Bundes-Djassa, nous offrons à nos clients un moyen économique de vendre des produits ou de faire de la publicité.

Contactez notre service marketing!

* Email: marketing@dsfts.cf
* Tél: +49 1521 18 66 691`;
const en = `# Bundes Djassa

## Do you want to sell goods or place an advertisement?

With the Bundes-Djassa we offer our customers a cost-effective way to sell goods or advertise.

Contact our marketing department!

* Email: marketing@dsfts.cf
* Phone: +49 1521 18 66 691`;

class Djassa extends Component {
  render() {
    const { t } = this.props;
    document.title = t("Djassa") + " - D.S.FTS";
    var txt = de;
    if (i18next.language === 'fr') {
      txt = fr;
    }
    else if (i18next.language === 'en') {
      txt = en;
    }
    return (
      <TextPage>
        <ReactMarkdown children={txt}>
        </ReactMarkdown>
      </TextPage>
    );
  }
}

export default withTranslation()(Djassa);
